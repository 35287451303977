import { createMuiTheme } from '@material-ui/core/styles';

export const Theme = createMuiTheme({
    palette: {
        primary: {
            light: '#B2DFDB',
            main: '#009988',
            dark: '#00796B',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#D7CCC8',
            main: '#ffa81d',
            dark: '#cf7800',
            contrastText: '#000000',
        },
    },
    overrides: {
        MuiFormLabel: {
            root: {
                color: "#1a237e",
            }
        },
    },

});
