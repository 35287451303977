import React,{useState, useEffect} from 'react';
import {RouteComponentProps, Redirect} from 'react-router-dom';
import useReactRouter from 'use-react-router';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
//import FormLabel from '@material-ui/core/FormLabel';
//import MultiLines from './MultiLines';
import store from "./store";
//import ColInput from './ColInput';
import Loading from './Loading';
import Toast from './Toast';
//import {Tools} from './Tools';
import {UserContainer} from './UserContainer';
import {Inc,Profile,NewsDoc} from './Inc';
//import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Yesno from './Yesno';

interface Props {
    new:boolean,
}

interface RCProps extends RouteComponentProps<{id:string}> {
	
}

const NewsInput = (props:Props)=> {
    const userState = UserContainer.useContainer();
    const rcp:RCProps = useReactRouter();
    const defRemoveTags:string[] = [];
    const [states,setStates] = useState({
        id:"",
        report:Inc.defaultNewsDoc(),
        tag: "",
        profile: Inc.defaultProfile(),
        removeTags: defRemoveTags,
        redirect:false,
        edited:false,
        isTagPick:false,
        isLoading:true,
        yesnoId:"",
    })
    const [vali,setVali] = useState({
        errors:Inc.defaultNewsInputErrors(),
        message:"",
    });

    const loadNews = (uid:string,id:string)=>{
        setStates({...states,isLoading:true});
        let report:NewsDoc;
        let profile:Profile;
        //store.getNews(id,(res:NewsDoc)=>{
        //    //console.log("getNews");
        //    report = res;
        //    if(profile){
        //        set();
        //    }
        //});
        loadProfile( (res:Profile)=>{
            //console.log("loadProfile");
            profile = res;
            if(report){
                set();
            }
        });
        const set = ()=>{
            setStates({...states,id:id,report:report,profile:profile,isLoading:false});      
        }
    }

    const validate = ()=>{
        if( !userState.user ){
            return false;
        }
        let errors = Inc.defaultNewsInputErrors();

        window.scroll(0,0);
        setStates({...states,isLoading:true});

        //更新バージョンをチェック
        store.getProfile(userState.user.uid,(profile:Profile)=>{
            saveNewsAndProfile();
        },()=>{
            setVali({errors,message:"登録できませんでした"});
        });
    }

    const saveNewsAndProfile = ()=>{
        if( !userState.user ){
            return false;
        }
    }

    const remove = ()=>{
    }

    const loadProfile = (func:any)=>{
        if(!userState.user){
            return;
        }
        store.getProfile(userState.user.uid,(profile:Profile)=>{
            userState.setProfile(profile);
            func(profile);
        },()=>{
            setStates({...states,profile:Inc.defaultProfile(),isLoading:false});
        });
    }

    const closeToast = ()=>{
        setVali({...vali,message:""});
    }

    const confirm = ()=>{
        setStates({...states,yesnoId:"remove"});
    }

    const safeBack = ()=>{
        if( states.edited ){
            setStates({...states,yesnoId:"back"});
        } else {
            setStates({...states,yesnoId:"",redirect:true});
        }
    }

    const answerYes = ()=>{
        if( states.yesnoId==="back" ){
            setStates({...states,yesnoId:"",redirect:true});
        }
        if( states.yesnoId==="remove" ){
            remove();
        }
    }

    const answerNo = ()=>{
        setStates({...states,yesnoId:""});
    }


    useEffect( ()=>{
        if( !userState.user ){
            return;
        }
        if( !props.new && rcp.match.params.id ){
            loadNews(userState.user.uid,rcp.match.params.id);
        } else {
            loadProfile( (profile:Profile)=>{
                setStates({...states,profile:profile,isLoading:false});
            })
        }
    },[userState.user]);

    if( !userState.ready ){
        return (null);
    }

    if( states.redirect ){
        return (
            <Redirect to="/"/>
        )
    }

    let mesYesno = "";
    if( states.yesnoId==="back" ){
        mesYesno = "編集内容は失われますがよろしいですか？";
    }
    if( states.yesnoId==="remove" ){
        mesYesno = "削除してよろしいですか？";
    }
   
    return (
        <div id="event_input" className="miniRoot">
            <div className="paper">
                {states.id!=="" ? (
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <FormControl fullWidth={true}>
                                <Button variant="outlined" color="secondary" onClick={confirm}>削除</Button>
                            </FormControl>
                        </Grid>
                        <Grid item xs={9}>
                            <FormControl fullWidth={true}>
                                <Button variant="contained" color="primary" onClick={validate}>決定</Button>
                            </FormControl>
                        </Grid>
                    </Grid>

                ):(
                    <FormControl fullWidth={true}>
                        <Button variant="contained" color="primary" onClick={validate}>決定</Button>
                    </FormControl>
                )}
                <div style={{marginTop:"1em",textAlign:"center"}}>
                    <Button variant="contained" color="default" onClick={safeBack}>やめる</Button>
                </div>
            </div>
            <Toast mes={vali.message} close={closeToast}/>
            <Yesno mes={mesYesno} yes={answerYes} no={answerNo} isOpen={states.yesnoId!==""}/>
            <Loading isLoading={states.isLoading}/>
        </div>
    );
}

export default NewsInput;
