import React,{useEffect,useState} from 'react';
import {Redirect} from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ColInput from './ColInput';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
//import IconButton from '@material-ui/core/IconButton';
//import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
//import Yesno from './Yesno';
//import MultiLines from './MultiLines';
import Loading from './Loading';
import store from "./store";
import {UserContainer} from './UserContainer';
import {News,Profile,NewsSearch} from './Inc';
//import "./Main.scss";

interface Props {
    
}

const NewsList = (props:Props)=> {
    const defNewss:News[] = [];
    const defSearch:NewsSearch = {
        date: "",
        title: "",
    };
    const [states,setStates] = useState({
        reports:defNewss,
        removeIndex:-1,
        endDateKey: "",
        isEnd:false,
        isLoading:true,
        isTitlePick:false,
        searchTitle: "",
        searchDate: "",
        redirectTo: "",
    })
    const userState = UserContainer.useContainer();
    const checkProfile = (profile?:Profile)=>{
        if(!profile || profile.name==="" ){
            setStates({...states,redirectTo:"/profile/input"})
        } else {
            updateList();
        }
    }

    const updateList = (searchTitle?:string)=>{
        if( !userState.user ){
            return;
        }
        if(!searchTitle){
            searchTitle = "";
        }
        store.getNewss( userState.user.uid, states.endDateKey , defSearch , (reports:News[])=>{
            let endDateKey = states.endDateKey;
            let isEnd = false;
            if( reports.length>0 ){
                endDateKey = reports[reports.length-1].doc.dateKey;
            } else {
                isEnd = true;
            }
            setStates({...states,reports:reports,endDateKey:endDateKey,isEnd:isEnd,isLoading:false,removeIndex:-1});
        });
    }

    const loadMore = ()=>{
        if( !userState.user ){
            return;
        }
        setStates({...states,isLoading:true});
        store.getNewss( userState.user.uid, states.endDateKey , defSearch , (reports:News[])=>{
            let endDateKey = states.endDateKey;
            let isEnd = false;
            if( reports.length>0 ){
                endDateKey = reports[reports.length-1].doc.dateKey;
            } else {
                isEnd = true;
            }
            setStates({...states,reports:states.reports.concat(reports),endDateKey:endDateKey,isEnd:isEnd,isLoading:false,removeIndex:-1});
        });
    }

    const changeSearchDate = (name:string,value:string)=>{
        setStates({...states,searchDate:value});
    }
    const changeSearchTitle = (name:string,value:string)=>{
        setStates({...states,searchTitle:value});
    }
    const search = ()=>{
        updateList(states.searchTitle);
    }
    const openTitlePick = ()=>{
        setStates({...states,isTitlePick:true});
    }
    const clearSearchTitle = ()=>{
        setStates({...states,searchTitle:""});
    }

    useEffect( ()=>{
        if(!userState.user){
            return;
        }
        if( !userState.hasProfile() ){
            store.getProfile(userState.user.uid,(profile:Profile)=>{
                userState.setProfile(profile);
                checkProfile(profile);
            },(error:any)=>{
                checkProfile();
            });
        } else {
            checkProfile(userState.getProfile());
        }
    },[userState.user]);

    if( !userState.ready ){
        return (null);
    }

    if( states.redirectTo!=="" ){
        return (
            <Redirect to={states.redirectTo}/>
        )
    }

    const profile = userState.getProfile();

    return (
        <div id="report_list" className="smallRoot">
            <div className="reports">
                <h2>{profile.name}さんのニッキ</h2>


                {!states.isEnd &&
                <div className="more">
                    <Button variant="contained" color="primary" onClick={loadMore} style={{width:"100%"}}>もっと表示</Button>
                </div>
                }
            </div>
            <div className="search">
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <FormControl fullWidth={true}>
                            <FormLabel component="label" style={{marginBottom:"0.4em"}}>検索タグ</FormLabel>
                            <Button size="small" variant="contained" color="default" onClick={openTitlePick}>選択</Button>
                        </FormControl>
                    </Grid>
                    <Grid item xs={9}>
                        <ColInput name="" id="tag" type="text" icon="cancel" onIconClick={clearSearchTitle} maxLength={20} placeholder="" value={states.searchTitle} change={changeSearchTitle}/>
                    </Grid>
                </Grid>
                <ColInput name="日付" id="date" type="date" value={states.searchDate} change={changeSearchDate}/>

                <div className="btns">
                    <Button variant="outlined" color="primary" onClick={search}>検索する</Button>
                </div>
            </div>
            <Loading isLoading={states.isLoading}/>
        </div>
    );
}

export default NewsList;
