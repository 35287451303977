import React from 'react';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


const NotFound = ()=> {
    return (
        <div id="secret" className="mediumRoot">
            <div className="view">
                <h1>
                    <span className="icon"><FontAwesomeIcon icon="user-lock"/></span>
                    ページが見つかりません
                </h1>
                <div className="foreword">
                    <div style={{textAlign:"center"}}>
                        このページは存在しません。削除されたのかも。
                    </div>
                </div>
                <div className="contents">


                </div>
                <div style={{marginTop:"2em",marginBottom:"6em",textAlign:"center"}}>
                    <Link to="/" className="link"><Button variant="outlined" color="primary">トップへ</Button></Link>
                </div>

            </div>
        </div>
    );
}

export default NotFound;
