import React,{useEffect,useState} from 'react';
import {useParams} from 'react-router-dom'//yarn add @types/react-router-dom
import Loading from './Loading';
import Secret from './Secret';
import NotFound from './NotFound';
import MultiLines from './MultiLines';
import ClimbUp from './ClimbUp';
import {Inc,NewsDoc,NewsContent,NewsLink,NewsHistory} from "./Inc";
import store from './store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const View = ()=> {
    const [news,setNews] = useState(Inc.defaultNews());
    const [notFound,setNotFound] = useState(false);
    const {gid} = useParams();

    const getNews = async ()=> {
        try {
            if( gid ){
                const ref = await store.findNews(gid);
                let flag = false;
                ref.forEach((doc) => {
                    console.log(gid,doc.data());
                    setNews({
                        id: gid,
                        doc: doc.data() as NewsDoc,
                    });
                    flag = true;
                });
                return flag;
            }
        } catch(error) {
            throw error;
        }
    }

    useEffect( ()=>{
        getNews().then((result)=>{
            if( result ){
                //console.log("found!");
            } else {
                //console.log("not found!");
                setNotFound(true);
            }
        })
        .catch((error)=>{
            console.log("error",error);
        });
    },[gid])

    if( notFound ){
        return (
            <NotFound/>
        )
    }

    if( !news.doc.createdAt ){
        return (
            <Loading isLoading={true}/>
        )
    }

    if( news.doc.secret==="0" ){
        return (
            <Secret/>
        );    
    }

    return (
        <div id="view" className="mediumRoot">
            <div className="view">
                <h1>
                    <span className="icon"><FontAwesomeIcon icon="dice"/></span>
                    {news.doc.title}
                </h1>
                <div className="foreword">
                    <MultiLines text={news.doc.foreword}/>
                    {news.doc.historys &&
                        <div className="lastUpdate">
                            最終更新：<span>{news.doc.historys[news.doc.historys.length-1].date}</span><br/>
                            {news.doc.historys[news.doc.historys.length-1].text}<br/>
                        </div>
                    }
                </div>
                <div className="contents">
                {news.doc.contents.map((content:NewsContent,index:number)=>{
                    const key = "content" + index;
                    let url = "";
                    if( content.code ){
                        const result = /src="(https:[^"]+)"/.exec(content.code);
                        if(result && result[1]){
                            url = result[1];        
                        }
                    }
                    let isNew = false;
                    if( content.isNew && content.isNew===true ){
                        isNew = true;
                    }
                    return (
                        <div key={key} className="content">
                            <h2>
                                {content.title}
                                {isNew &&
                                    <span style={{marginLeft:"0.5em",color:"orange",fontSize:"80%"}}>NEW!</span>
                                }
                            </h2>
                            {url!=="" &&
                            <div className="map">
                                <iframe title="地図" src={url} style={{width:"100%",maxWidth:"800px",minHeight:"300px",border:"0"}}></iframe>
                            </div>
                            }
                            <div className="text">
                                <MultiLines text={content.text}/>
                            </div>
                            <div className="imgs">
                            {content.imgs && content.imgs.map((url:string,index:number)=>{
                                const key = "img" + index;
                                return (
                                    <div style={{width:"100px",height:"100px",float:"left",border:"1px solid silver",marginRight:"5px",marginBottom:"5px"}} key={key}>
                                        <a href={url}><img src={url} style={{maxWidth:"100%",maxHeight:"100%",}}/></a>
                                    </div>
                                )
                            })}
                                <div style={{clear:"both"}}/>
                            </div>
                            <div className="links">
                            {content.links && content.links.map((link:NewsLink,index:number)=>{
                                const key = "link"+index;
                                return (
                                    <div className="link" key={key}>
                                        <a href={link.url} className="link" rel="noopener noreferrer" target="_blank">{link.title}</a>
                                    </div>
                                )
                            })}
                            </div>
                        </div>
                    )
                })}
                    <div className="content">
                        <h2>このページについて</h2>
                        <div className="text">
                            <div>このページはレトロインクで開発中の簡易告知サービスで作成しました。文字通りの「開発中」なので、下部のメニューなどは反応しません。それ以外にも雑なところがありますがご容赦ください。</div>
                        </div>
                    </div>

                </div>
                {news.doc.historys &&
                <div className="historys">
                    <h3>更新履歴</h3>
                    <ul>
                    {news.doc.historys.map((history:NewsHistory,index:number)=>{
                        const key = "history"+index;
                        return (
                            <li key={key}>
                                {history.text}
                                <span>{history.date}</span>
                            </li>
                        )
                    })}
                    </ul>
                </div>
                }
            </div>
            <ClimbUp/>
        </div>
    );
}

export default View;
