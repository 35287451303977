import {db} from './firebase';
import {NewsDoc,News,Profile,NewsSearch} from './Inc';
import { Tools } from './Tools';

export default class store {
    static users = process.env.REACT_APP_FIREBASE_USER_COLLECTION ? process.env.REACT_APP_FIREBASE_USER_COLLECTION : "testusers";
    static newss = process.env.REACT_APP_FIREBASE_NEWS_COLLECTION ? process.env.REACT_APP_FIREBASE_NEWS_COLLECTION : "testnewss";
    static getNews = async (id:string)=>{
        return await db.collection(store.newss).doc(id).get();
    }
    static findNews = async (code:string)=>{
        return await db.collection(store.newss).where("code","==",code).limit(1).get();
    }










    static getNewss = (uid:string,endDateKey:string,search:NewsSearch,func:any)=>{
        let sa:"<"|"<=" = "<";
        if(endDateKey===""){
            if(search.date){
                endDateKey = Tools.getDateKey(search.date);
                sa = "<=";
            } else {
                endDateKey="99999999";
            }
        }
        if( search.title!=="" ){
            db.collection(store.users).doc(uid).collection("Newss").where("createdAt",sa,endDateKey).where("title","==",search.title).orderBy('createdAt','desc').limit(10).get().then((response) => {
                let list:News[] = [];
                response.forEach((doc) => {
                    list.push( {id:doc.id,doc:doc.data() as NewsDoc} );
                });
                func(list);
            });        
        } else {
            db.collection(store.users).doc(uid).collection("Newss").where("createdAt",sa,endDateKey).orderBy('dateKey','desc').limit(10).get().then((response) => {
                let list:News[] = [];
                response.forEach((doc) => {
                    list.push( {id:doc.id,doc:doc.data() as NewsDoc} );
                });
                func(list);
            });        
        }
    }
    static getRangeNewss = (uid:string,fromDateKey:string,toDateKey:string,func:any)=>{
        db.collection(store.users).doc(uid).collection("Newss").where("dateKey",">=",fromDateKey).where("dateKey","<=",toDateKey).orderBy('dateKey','asc').get().then((response) => {
            let list:News[] = [];
            response.forEach((doc) => {
                list.push( {id:doc.id,doc:doc.data() as NewsDoc} );
            });
            func(list);
        });        
    }
    static addNews = (NewsDoc:NewsDoc,funcSuccess:any,funcFailure?:any)=>{
        db.collection(store.users).doc(NewsDoc.uid).collection("Newss").add(NewsDoc)
        .then((response) => {
            funcSuccess();    
        })
        .catch( (error)=>{
            if(funcFailure){
                funcFailure();
            }
        });
    }
    static updateNews = (NewsDoc:NewsDoc,id:string,funcSuccess:any,funcFailure?:any)=>{
        const ref = db.collection(store.users).doc(NewsDoc.uid).collection("Newss").doc(id);
        //console.log("update",id,NewsDoc);
        ref.set(NewsDoc).then(() => {
            funcSuccess();
        })
        .catch( (error) => {
            console.log(`データを取得できませんでした (${error})`);
            if(funcFailure){
                funcFailure();
            }
        });        
    }
    static removeNews = (uid:string,id:string,funcSuccess:any,funcFailure?:any)=>{
        db.collection(store.users).doc(uid).collection("Newss").doc(id).delete()
        .then(() => {
            funcSuccess();
        })
        .catch( (error)=>{
            if(funcFailure){
                funcFailure();
            }
        });
    }
    static nextFirstNewsDateKey = (uid:string,firstDateKey:string,funcSuccess:any,funcFailure?:any)=>{
        const ref = db.collection(store.users).doc(uid).collection("Newss").where("dateKey",">",firstDateKey).orderBy("dateKey","asc").limit(1);
        ref.get().then((response) => {
            if( response.size>0 ){
                response.forEach((doc)=>{
                    const News:NewsDoc = doc.data() as NewsDoc;
                    funcSuccess( News.dateKey );
                });
            } else {
                if( funcFailure ){
                    funcFailure();
                }
            }
        })
        .catch( (error) => {
            console.log(`データを取得できませんでした (${error})`);
            if( funcFailure ){
                funcFailure();
            }
        });        
    }

    static setProfile = (uid:string,profile:Profile,funcSuccess:any,funcFailure?:any)=>{
        db.collection(store.users).doc(uid).set(profile)
        .then((response) => {
            funcSuccess();    
        })
        .catch( (error)=>{
            if(funcFailure){
                funcFailure();
            } else {
                console.log(error);
            }
            
        });
    }
    static updateProfileParam = (uid:string,param:string,value:any,funcSuccess:any,funcFailure?:any)=>{
        db.collection(store.users).doc(uid).update({[param]:value})
        .then((response) => {
            funcSuccess();    
        })
        .catch( (error)=>{
            if(funcFailure){
                funcFailure();
            } else {
                console.log(error);
            }
            
        });
    }
    static getProfile = (uid:string,funcSuccess:any,funcFailure?:any)=>{
        const ref = db.collection(store.users).doc(uid)
        ref.get().then((doc) => {
            if (doc.exists) {
                funcSuccess( doc.data() as Profile );
            } else if(funcFailure){
                funcFailure();
            } else {
                console.log("not found");
            }
        })
        .catch( (error) => {
            console.log(`データを取得できませんでした (${error})`);
        });        
    }
    static getPublicProfile = (publicKey:string,funcSuccess:any,funcFailure?:any)=>{
        const ref = db.collection(store.users).where("publicKey","==",publicKey).limit(1);
        ref.get().then((response) => {
            if(response.size>0){
                response.forEach((doc)=>{
                    funcSuccess( doc.id , doc.data() as Profile );
                });
            } else {
                if( funcFailure ){
                    funcFailure();
                }
            }
        })
        .catch( (error) => {
            console.log(`データを取得できませんでした (${error})`);
        });        
    }

    static getUniqueId(myStrong?: number): string {
        let strong = 10000;
        if (myStrong) strong = myStrong;
        return (
            ("0000" + Math.floor(strong * Math.random()).toString(16)).slice(-4) + ("00000000000" + new Date().getTime().toString(16)).slice(-11)
        );
    }
}