import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
//import {Link} from 'react-router-dom';
//import Button from '@material-ui/core/Button';
//import About from './About';

const First = ()=> {
    return (
        <div id="secret" className="mediumRoot">
            <div className="view">
                <h1>
                    <span className="icon"><FontAwesomeIcon icon="user-lock"/></span>
                    試験公開中
                </h1>
                <div className="foreword">
                    <div style={{textAlign:"center",marginBottom:"12em"}}>
                        Parakeet Newsはまだ開発途中です。<br/>
                        新規のユーザー登録やニュースの登録はうけたまわっておりません。
                    </div>
                </div>
                <div className="contents">


                </div>

            </div>
        </div>

    );
}

export default First;
