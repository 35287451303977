import React from 'react';
//import {Link} from 'react-router-dom'//yarn add @types/react-router-dom
import Grid from '@material-ui/core/Grid';
//import Toolbar from '@material-ui/core/Toolbar';
//import AccountBoxIcon from '@material-ui/icons/AccountBox';
//import Button from '@material-ui/core/Button';
//import IconButton from '@material-ui/core/IconButton';
//import Drawer from '@material-ui/core/Drawer';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
//import Logo from './Logo';
//import {Theme} from './Theme';
//import {UserContainer} from './UserContainer';
import "./TopBar.scss";

interface Props {
    //redirect(to:string):void,
}

const Menus = (props:Props)=> {
    //const userState = UserContainer.useContainer();

    return (
        <div id="menus" className="menus">
            <div className="mediumRoot">
                <Grid container spacing={1}>
                    <Grid item xs={6} md={4}>
                        <h3>Menu</h3>
                        <ul className="menus">
                            <li>Parakeet News?</li>
                            <li>運営会社</li>
                            <li>利用規約</li>
                            <li>プライバシーポリシー</li>
                        </ul>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <h3>Link</h3>
                        <ul className="menus">
                            <li><a href="https://store.retro-biz.com/" target="_blank" rel="noopener noreferrer" className="link">架空ストア</a></li>
                            <li><a href="https://www.retro-ink.com/" target="_blank" rel="noopener noreferrer" className="link">有限会社レトロインク</a></li>
                        </ul>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className="panel">
                            <h4>Parakeet!</h4>
                            <ul className="menus">
                                <li><FontAwesomeIcon icon="sign-in-alt"/> ログイン</li>
                                <li><FontAwesomeIcon icon="edit"/> 編集</li>
                            </ul>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="copyright">
                    <FontAwesomeIcon icon="copyright"/> レトロインク　2020
                </div>
        </div>
    )
}

export default Menus;