import React,{useEffect,useState} from 'react';
import {Redirect} from 'react-router-dom';
//import FormControl from '@material-ui/core/FormControl';
//import FormLabel from '@material-ui/core/FormLabel';
import ColInput from './ColInput';
import Grid from '@material-ui/core/Grid';
//import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
//import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
//import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
//import MultiLines from './MultiLines';
import Loading from './Loading';
import store from "./store";
import First from "./First";
import About from './About';
import ClimbUp from './ClimbUp';
import {UserContainer} from './UserContainer';
import {Profile,News,NewsSearch,Inc} from './Inc';
//import "./Main.scss";

interface Props {
    uid?:string,
    profile?:Profile,
}

const Main = (props:Props)=> {
    const defNewss:News[] = [];
    const defSearch:NewsSearch = {
        title: "",
        date: "",
    };
    const [states,setStates] = useState({
        uid: "",
        profile:Inc.defaultProfile(),
        Newss:defNewss,
        endDateKey: "",
        isEnd:false,
        isLoading:true,
        isTagPick:false,
        search:defSearch,
        redirectTo: "",
    })
    const [about,setAbout] = useState<"company"|"warning"|"copyright"|"">("");

    const userState = UserContainer.useContainer();

    const updateList = (search?:NewsSearch)=>{
        if(!search){
            search = defSearch;
        }
        if( states.uid!=="" ){
            store.getNewss( states.uid, "" , search , (Newss:News[])=>{
                let endDateKey = states.endDateKey;
                let isEnd = false;
                if( Newss.length>0 ){
                    //endDateKey = Newss[Newss.length-1].doc.dateKey;
                } else {
                    isEnd = true;
                }
                setStates({...states,Newss:Newss,endDateKey:endDateKey,isEnd:isEnd,isLoading:false});
            });
        }
    }

    const loadMore = ()=>{
        setStates({...states,isLoading:true});
        store.getNewss( states.uid, states.endDateKey , states.search , (Newss:News[])=>{
            let endDateKey = states.endDateKey;
            let isEnd = false;
            if( Newss.length>0 ){
                //endDateKey = Newss[Newss.length-1].doc.dateKey;
            } else {
                isEnd = true;
            }
            setStates({...states,Newss:states.Newss.concat(Newss),endDateKey:endDateKey,isEnd:isEnd,isLoading:false});
        });
    }

    const changeSearch = (name:string,value:string)=>{
        setStates({...states,search:{...states.search,[name]:value}});
    }
    const search = ()=>{
        setStates({...states,isLoading:true});
        window.scrollTo(0,0);
        updateList(states.search);
    }
    const setSearchTitle = (name:string)=>{
        setStates({...states,search:{...states.search,title:name},isTagPick:false});
    }
    const clearSearchTitle = ()=>{
        setStates({...states,search:{...states.search,title:""}});
    }
    const clearSearchDate = ()=>{
        setStates({...states,search:{...states.search,date:""}});
    }
    const openAbout = (e: React.MouseEvent<HTMLElement>)=>{
        if( !e.currentTarget.dataset.type ){
			return;
        }
        if( e.currentTarget.dataset.type==="company"){
            setAbout("company");
        }
        if( e.currentTarget.dataset.type==="copyright"){
            setAbout("copyright");
        }
        if( e.currentTarget.dataset.type==="warning"){
            setAbout("warning");
        }
    }
    const closeAbout = ()=>{
        setAbout("");
    }

    useEffect( ()=>{
        if( !userState.ready ){
            return;
        }
        if(userState.user){
            if( !userState.hasProfile() ){
                const uid = userState.user.uid;
                store.getProfile(uid,(profile:Profile)=>{
                    userState.setProfile(profile);
                },(error:any)=>{
                    userState.setProfile(Inc.defaultProfile());
                    //console.log("here?");
                    //checkProfile();
                });
                return;
            } else {
                const profile = userState.getProfile();
                if(!profile || profile.name==="" ){
                    setStates({...states,redirectTo:"/profile/new"})
                } else if( props.uid && props.profile ){
                    setStates({...states,uid:props.uid,profile:props.profile});
                } else {
                    setStates({...states,uid:uid,profile:profile});
                }
            }
        } else {
            if( !states.uid || !states.profile ){
                if( props.uid && props.profile ){
                    setStates({...states,uid:props.uid,profile:props.profile});
                    return;
                }
            }
        }
        if( states.uid && states.profile ){
            updateList();
        }
    },[userState.user,states.uid,userState.hasProfile(),props.uid]);

    if( !userState.ready ){
        return (null);
    }

    if( states.redirectTo!=="" ){
        return (
            <Redirect to={states.redirectTo}/>
        )
    }

    let uid = "";
    if( userState.user && userState.user.uid ){
        uid = userState.user.uid;
    }

    if( !userState.user && states.uid==="" ){
        return (
            <First/>
        );
    }

    return (
        <div id="News_list" className="smallRoot">
            <div className="Newss">
                <h2 className="withSearch">
                    {states.profile.name}さんのニュース
                    <div className="goSearch"><a href="#search"><SearchIcon/></a></div>
                </h2>

                {!states.isEnd &&
                <div className="more">
                    <Button variant="outlined" color="primary" onClick={loadMore} style={{width:"100%"}}>もっと表示</Button>
                </div>
                }
            </div>
            <div className="search" id="search">
                <Grid container spacing={1}>
                    <Grid item xs={3}>

                    </Grid>
                    <Grid item xs={9}>
                        <ColInput name="" id="tag" type="text" icon="cancel" onIconClick={clearSearchTitle} maxLength={20} placeholder="" value={states.search.title} change={changeSearch}/>
                    </Grid>
                </Grid>
                <ColInput name="日付" id="date" type="date" icon="cancel" onIconClick={clearSearchDate} value={states.search.date} change={changeSearch}/>

                <div className="btns">
                    <Button variant="outlined" color="primary" onClick={search}>検索する</Button>
                </div>
            </div>
            <About type={about} isOpen={about!==""} close={closeAbout}/>
            <Loading isLoading={states.isLoading}/>
        </div>
    );
}

export default Main;
