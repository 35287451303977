import React from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import {Tools} from './Tools';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface Props {
    type: "company"|"warning"|"copyright"|"",
    close():void,
    isOpen:boolean,
}

const About = (props:Props)=> {
    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
            onClose={props.close}
        >
            <div style={Tools.getModalStyle()} className="about">
                <div className="dialogRoot">
                    <div style={{marginBottom:"1em"}}>
                        {props.type==="company" &&
                        <div>
                            <h2>運営会社</h2>
                            <div className="text">
                            <p>Peterは有限会社レトロインクが作成し、運営しています。</p>
                            <p className="copyright">
                                Peter<br/>
                                ver.20200201<br/>
                                <br/>
                                <FontAwesomeIcon icon="copyright"/> レトロインク　2020<br/>
                                <a href="https://www.retro-ink.com/" target="_blank" rel="noopener noreferrer" className="link">https://www.retro-ink.com/</a><br/>
                            </p>
                            </div>
                        </div>
                        }
                        {props.type==="copyright" &&
                        <div>
                            <h2>著作権等</h2>
                            <div className="text">
                            <p>Peterの著作権は開発・運営元である有限会社レトロインク（以下、弊社）が所有します。</p>
                            <p>Peterに入力されたテキスト（以下、テキスト）の著作権はテキストを入力したユーザー（以下、ユーザー）が有します。弊社がPeter外においてユーザーの許可なくテキストを使用することはありません。</p>
                            </div>
                        </div>
                        }
                        {props.type==="warning" &&
                        <div>
                            <h2>注意事項</h2>
                            <div className="text">
                            <p>Peterの運営期間は未定です。永遠にあるものではありません。失われて困るものは入力しないようにご注意ください。</p>
                            <p>Peterに第三者に知られて困る内容は入力しないようにご注意ください。</p>
                            <p>Peterは突然仕様が変わったり、停止したり、終了したりするかも知れません。驚かないでください。</p>
                            </div>
                        </div>
                        }
                    </div>
                    <Button variant="outlined" color="primary" style={{width:"100%"}} onClick={props.close}>閉じる</Button>
                </div>
            </div>
        </Modal>
    );
}

export default About;
