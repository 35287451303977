import React,{useState, useEffect} from 'react';
import {Link,Redirect} from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
//import {Tools} from './Tools';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {UserContainer} from './UserContainer';
import ProfileName from './ProfileName';
import ProfileEmail from './ProfileEmail';
//import ProfileSecret from './ProfileSecret';
import store from './store';
import Loading from './Loading';
import Toast from './Toast';
import {Profile, Inc} from './Inc';
//import useStyles from './Styles';


interface Props {
}

const ProfileDetail = (props:Props)=> {
    const userState = UserContainer.useContainer();
    const [states,setStates] = useState({
        profile: Inc.defaultProfile(),
        name: "",
        redirectTo: "",
        modal: "",
        isLoading: true,
    });
    const [toast,setToast] = useState("");

    const logout = ()=>{
        userState.signOut();
        setStates({...states,redirectTo:"/login"});
    }
    const closeToast = ()=>{
        setToast("");
    }
    const openName = ()=>{
        setStates({...states,modal:"name"});
    }
    const openEmail = ()=>{
        setStates({...states,modal:"email"});
    }
    const closeModal = ()=>{
        setStates({...states,modal:""});
    }
    const updateName = (newName:string)=>{
        if( userState.user ){
            store.updateProfileParam(userState.user.uid,"name",newName,()=>{
                const newProfile = {...states.profile,"name":newName};
                userState.setProfile(newProfile);
                setStates({...states,profile:newProfile,modal:""});
            },()=>{
                setToast("変更できませんでした");
            });
        }
    }
    const updateEmail = (newEmail:string)=>{
        if( userState.user ){
            userState.updateEmail(newEmail,()=>{
                setStates({...states,modal:""});
            },()=>{
                setToast("変更できませんでした");
            })
        }
    }
    const copy = ()=>{
        setToast("URLをクリップボードにコピーしました");
    }

    useEffect(()=>{
        if(!userState.user){
            return;
        }
        store.getProfile(userState.user.uid,(profile:Profile)=>{
            userState.setProfile(profile);
            setStates({...states,isLoading:false,redirectTo:"",profile:profile});
        },()=>{
            setStates({...states,redirectTo:"/"});
        })
    },[userState.user]);

    if( states.redirectTo!=="" ){
        return (
            <Redirect to={states.redirectTo}/>
        )
    }

    if( !userState.hasProfile() ){
        return (null)
    }

    //const url = "https://peter.retro-ink.com/" + states.profile.publicKey;
    //const link = "/" + states.profile.publicKey;

    const url = "https://parakeet.retro-ink.com/";
    const link = "/";

    return (
        <div id="profile_detail" className="smallRoot">
            <div className="paper">
                <div className="profile">
                    <h2>プロフィール</h2>
                    <FormControl fullWidth={true}>
                        <FormLabel component="label" className="formLabel">名前</FormLabel>
                        <div className="colData">
                            <div className="value">{states.profile.name}</div>
                            <div className="btn">
                                <IconButton size="small" color="primary" aria-label="edit-name" onClick={openName}>
                                    <FontAwesomeIcon icon="edit"/>
                                </IconButton>
                            </div>
                        </div>
                    </FormControl>
                    <FormControl fullWidth={true}>
                        <FormLabel component="label" className="formLabel">メールアドレス</FormLabel>
                        <div className="colData">
                            <div className="value">{userState.user ? userState.user.email : "未登録"}</div>
                            <div className="btn">
                                <IconButton size="small" color="primary" aria-label="edit-email" onClick={openEmail}>
                                    <FontAwesomeIcon icon="edit"/>
                                </IconButton>
                            </div>
                        </div>
                    </FormControl>
                    <FormControl fullWidth={true} style={{marginBottom:"2em"}}>
                        <FormLabel component="label" className="formLabel">公開用ＵＲＬ</FormLabel>
                        <div className="colData">
                            <div className="value"><span style={{fontSize:"90%"}}>{url}</span></div>
                            <div className="btn">
                                <Link to={link}><Tooltip title="ページを開く"><IconButton size="small" color="primary" aria-label="link" style={{marginRight:"0.5em"}}>
                                    <FontAwesomeIcon icon="external-link-square-alt"/>
                                </IconButton></Tooltip></Link>
                                <CopyToClipboard
                                    text={url}
                                    onCopy={copy}
                                >
                                <Tooltip title="URLをクリップボードにコピー"><IconButton size="small" color="primary" aria-label="copy-to-clipboard">
                                    <FontAwesomeIcon icon="clipboard"/>
                                </IconButton></Tooltip>
                                </CopyToClipboard>
                            </div>
                        </div>
                    </FormControl>
                    <FormControl fullWidth={true}>
                        <Button variant="contained" color="primary" style={{width:"100%"}} onClick={logout}>ログアウトする</Button>
                    </FormControl>

                    <div className="goMain">
                        <Link to="/" className="link"><Button variant="outlined" color="default">もどる</Button></Link>
                    </div>
                </div>
            </div>
            <Toast close={closeToast} mes={toast}/>
            <ProfileName close={closeModal} name={states.profile.name} update={updateName} isOpen={states.modal==="name"}/>
            <ProfileEmail close={closeModal} name={states.profile.name} email={userState.user && userState.user.email ? userState.user.email : ""} update={updateEmail} isOpen={states.modal==="email"}/>
            <Loading isLoading={states.isLoading}/>
        </div>
    );
}

export default ProfileDetail;
