export const CookieHashName = "parakeet20";
export const getCookieOptions = (domain:string | undefined)=>{
    //console.log("domain",domain);
    const expire = new Date();
    expire.setDate(expire.getDate() + 20);
    const options = {
        path: "/",
        expires: expire,
        maxAge: 86400*20,
        domain: domain,
        secure: false,
        httpOnly: false,
        sameSite: true,
    }
    return options;
};

export interface Dataset {
    id: string,
    label: string,    
}
export interface Profile {
    name: string,
}
export interface News {
    id: string,
    doc: NewsDoc,
}
export interface NewsDoc {
    uid: string,
    title: string,
    foreword: string,
    contents: NewsContent[],
    historys?: NewsHistory[],
    secret: string,
    dateKey: string,
    publishedAt?: Date,
    createdAt?: Date,
    updatedAt?: Date,
}
export interface NewsInputErrors {
    title: string,
    contents: string,
    secret: string,
}
export interface NewsHistory {
    date: string,
    text: string,
}
export interface NewsContent {
    title: string,
    text: string,
    code?: string,
    links?: NewsLink[],
    imgs?: string[],
    isNew?: boolean,
}
export interface NewsLink {
    title: string,
    url: string,
}
export interface NewsSearch {
    title: string,
    date: string,
}
export class Inc {
    static readonly secrets:Dataset[] = [
        {
            id:     "0",
            label:  "自分だけ見られる",
        },
        {
            id:     "1",
            label:  "誰でも見られる",
        },
    ];
    static readonly defaultProfile = ()=>{
        let profile:Profile = {
            name: "",
        };
        return profile;
    }
    static readonly defaultNewsContent = ()=>{
        let content:NewsContent = {
            title: "",
            text: "",
        };
        return content;
    }
    static readonly defaultNewsDoc = ()=>{
        let doc:NewsDoc = {
            uid: "",
            title: "",
            foreword: "",
            dateKey: "",
            contents: [],
            secret: "0",
        };
        return doc;
    }
    static readonly defaultNews = ()=>{
        let news:News = {
            id: "",
            doc: Inc.defaultNewsDoc(),
        };
        return news;
    }
    static readonly defaultNewsInputErrors = ()=>{
        let errors:NewsInputErrors = {
            title: "",
            contents: "",
            secret: "",
        }
        return errors;
    }

}
